import { ClientConfig } from 'src/core/ClientConfig';
import { createGlobalStyle, css } from 'styled-components';

export const RootCssVars = createGlobalStyle<{ clientConfig: ClientConfig.Type }>(
  ({ clientConfig: config }) => css`
    :root {
      /* Fonts */
      --font-family-body: ${config.theme.font.family};

      /* Weights */
      --font-weight-normal: ${config.theme.font.weight.normal};
      --font-weight-medium: ${config.theme.font.weight.medium};
      --font-weight-bold: ${config.theme.font.weight.bold};

      /* Colors */
      --color-black: ${config.theme.palette.black};
      --color-white: ${config.theme.palette.white};
      --color-primary: ${config.theme.palette.primary};
      --color-secondary: ${config.theme.palette.secondary};
      --color-tertiary: ${config.theme.palette.tertiary};
      --color-darkGrey: ${config.theme.palette.darkGrey};
      --color-mediumGrey: ${config.theme.palette.mediumGrey};
      --color-lightGrey: ${config.theme.palette.lightGrey};
      --color-veryLightGrey: ${config.theme.palette.veryLightGrey};
      --color-positive: ${config.theme.palette.positive};
      --color-negative: ${config.theme.palette.negative};

      /* Spacing */
      --s-1: 4px;
      --s-2: 8px;
      --s-3: 12px;
      --s-4: 16px;
      --s-5: 20px;
      --s-6: 24px;
      --s-7: 28px;
      --s-8: 32px;
      --s-10: 40px;
      --s-11: 44px;
      --s-12: 48px;
      --s-14: 56px;
      --s-16: 64px;

      /* Text size */
      --text-sm: 12px;
      --text-base: 14px;
      --text-lg: 16px;
      --text-xl: 20px;

      /* Line height */
      --line-height-sm: 18px;
      --line-height-base: 20px;
      --line-height-lg: 24px;
      --line-height-xl: 32px;

      /* Buttons */
      --button-text-size: 14px;
      --button-line-height: 24px;
      --button-weight: 600;
      --button-radius: ${config.theme.button.radius};
      --button-letter-spacing: ${config.theme.button.letterSpacing};
      --button-transform: ${config.theme.button.uppercase ? 'uppercase' : 'none'};
      --button-shadow: ${config.theme.button.shadow};
      --button-border: 1px solid var(--color-${config.theme.button.borderColor});
      --button-bg-color: var(--color-${config.theme.button.bgColor});
      --button-text-color: var(--color-${config.theme.button.textColor});

      /* Data collectors */
      --data-collector-name-color: var(--color-${config.theme.dataCollector.nameColor});
      --data-collector-icon-color: var(--color-${config.theme.dataCollector.iconColor});
      --data-collector-edit-link-color: var(--color-${config.theme.dataCollector.editLinkColor});

      /* Splash screen */
      --splash-bg-color: var(--color-${config.splash ? config.splash.bgColor : 'darkGrey'});
      --splash-text-color: var(--color-${config.splash ? config.splash.textColor : 'mediumGrey'});

      /* Header */
      --header-bg-color: var(--color-${config.header.bgColor});
      --header-border-color: var(--color-${config.header.borderColor});

      /* Add Font Face if there is a custom local font */
      ${config.theme.font.localFiles?.path && config.theme.font.localFiles?.fileName
        ? Object.keys(config.theme.font.weight).map(
            (weight) =>
              `@font-face {
            font-family: "${config.theme.font.localFiles?.fileName}";
            src: url('${config.theme.font.localFiles?.path}/${config.theme.font.localFiles?.fileName}-${weight}.woff2') format('woff2'),
                 url('${config.theme.font.localFiles?.path}/${config.theme.font.localFiles?.fileName}-${weight}.woff') format('woff');
            font-weight: ${weight};
            font-style: normal;
          }`
          )
        : ''}
    }
  `
);
