import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { InferGetServerSidePropsType } from 'next';
import { getServerSidePropsForPage } from 'src/ssr/public';
import { RootCssVars } from 'src/components/styles/RootCssVars';

type Index = InferGetServerSidePropsType<typeof getServerSidePropsForPage>;

const Index = ({ clientConfig }: Index): JSX.Element => {
  const { logo } = clientConfig.splash;

  return (
    <>
      <Container>
        <Logo>
          <Image alt={logo.alt} src={logo.src} width={logo.width} height={logo.height} loading="eager" />
        </Logo>
      </Container>
      <RootCssVars clientConfig={clientConfig} />
    </>
  );
};

export const getServerSideProps = getServerSidePropsForPage;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--splash-bg-color);
  color: var(--splash-text-color);
  padding: var(--s-12) var(--s-8) var(--s-6);
`;

const Logo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Index;
